import localFont from 'next/font/local'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        body3: React.CSSProperties
        body4: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        body3?: React.CSSProperties
        body4?: React.CSSProperties
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body3: true
        body4: true
    }
}

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightBold = 700

const engelVoelkersFontText = localFont({
    src: '../../public/fonts/EngelVoelkersText_Rg.woff2',
})

const engelVoelkersFontHead = localFont({
    src: '../../public/fonts/EngelVoelkersHead_Rg.woff2',
})

export const typography = {
    fontWeightRegular,
    fontWeightLight,
    fontWeightBold,
    fontFamily: engelVoelkersFontText.style.fontFamily,
    h1: {
        fontFamily: engelVoelkersFontHead.style.fontFamily,
        fontSize: '24px',
    },
    h2: {
        fontSize: '24px',
    },
    h3: {
        fontSize: '20px',
    },
    h4: {
        fontSize: '16px',
    },
    subtitle1: {
        fontSize: '11px',
    },
    body1: {
        fontSize: '20px',
    },
    body2: {
        fontSize: '16px',
    },
    body3: {
        fontSize: '14px',
    },
    body4: {
        fontSize: '12px',
    },
}
