const values = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1440,
}

const unit = 'px'

export const breakpoints = { values, unit }
