import { Environment } from '../const'

export const environment = (): Environment => {
    const environment = process.env.NEXT_PUBLIC_APP_ENV as Environment

    if (!Environment[environment]) {
        throw new Error('Unknown Environment: ' + environment)
    }

    return environment
}
