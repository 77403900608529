import { Language } from '@/lib/i18n'

export type StaticRoutes =
    | `/${Language}/legal-entities`
    | `/${Language}/licence-areas`
    | `/${Language}/licence-contracts`
    | `/${Language}/no-permission`
    | `/${Language}/shops`

export type DynamicRoutes =
    | `/${Language}/legal-entities/${string}`
    | `/${Language}/licence-contracts/${string}`
    | `/${Language}/shops/${string}`

export type Routes = StaticRoutes | DynamicRoutes

type RouteToTitleMapping = {
    [K in StaticRoutes]: K extends `/${Language}/legal-entities`
        ? 'legalEntities'
        : K extends `/${Language}/licence-areas`
          ? 'licenceAreas'
          : K extends `/${Language}/licence-contracts`
            ? 'contracts'
            : K extends `/${Language}/no-permission`
              ? 'permission.head'
              : 'shops'
}

export type RouteTitles = RouteToTitleMapping[StaticRoutes]

type ExtractParam<Path, NextPart> = Path extends `:${infer Param}` ? Record<Param, string> & NextPart : NextPart

export type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
    ? ExtractParam<Segment, ExtractParams<Rest>>
    : ExtractParam<Path, {}>

export const paths = {
    shops: '/shops',
    shopDetail: '/shops/:shopId',
    licenceContracts: '/licence-contracts',
    licenceContractDetail: '/licence-contracts/:licenceContractId',
    legalEntities: '/legal-entities',
    legalEntityDetail: '/legal-entities/:legalEntityId',
    noPermission: '/no-permission',
} as const

export type Paths = keyof typeof paths
