import dayjs, { Dayjs } from 'dayjs'

import { dateFormat } from '../const/format'
import { TFunction } from '../lib/i18n/use-translation'

import { LicenceContractLinkFragment } from '../../lib/generated/graphql'

const regex = new RegExp(/\s*Engel\s*&\s*Völkers\s*/gm)

/**
 * Format date to for ISO 8601 format, e.g. 2020-01-01
 * @param date
 */
export const formatDate = (date: Dayjs | string | number | null | undefined): string | null => {
    const parsed = dayjs(date)
    if (!date || !parsed.isValid()) {
        return null
    }

    return parsed.format(dateFormat)
}

export const formatValidDate = (date: Dayjs | string) => dayjs(date).format(dateFormat)

export const formatNameAbbrevation = (t: TFunction, name: string | null | undefined) => {
    if (!name || name.length < 1) {
        return t('N/A')
    }

    const nameWithoutEV = name.replace(regex, '')

    return nameWithoutEV
        .split(' ')
        .flatMap((c) => c[0]?.toUpperCase())
        .join('')
}

/**
 * Translate date to for each locale, e.g. 21.1.2020 or 21/1/2020
 * @param t i18n translation function
 * @param value date to translate
 */
export const translateDate = (t: TFunction, value?: Dayjs | string | null) => {
    if (!value || !dayjs(value).isValid()) {
        return undefined
    }

    return dayjs(value).format(t('dateFormat') || 'YYYY-MM-DD')
}

export const licenceContractName = (licenceContract: LicenceContractLinkFragment): string => {
    return `${licenceContract.licenceAreas?.map((a) => a.name).join(' / ')} ${licenceContract.businessUnit} ${
        licenceContract.startDate?.slice(0, 4) || '-'
    }/${(licenceContract.actualEndDate ? licenceContract.actualEndDate.slice(0, 4) : licenceContract.expectedEndDate?.slice(0, 4)) || '-'}`
}
