'use client'

import { useRouter } from 'next/navigation'

import { Language } from '@/lib/i18n'

import { useLanguage } from './use-language'

import { ExtractParams, Paths, paths } from '../types/routes'
import { href } from '../utils/href'

type RouterProps<T extends Paths> =
    ExtractParams<(typeof paths)[T]> extends Record<string, never>
        ? { path: T; params?: never }
        : { path: T; params: ExtractParams<(typeof paths)[T]> }

export type HrefProps<T extends Paths> = RouterProps<T> & { language: Language }

export const useLocalizedRouter = () => {
    const router = useRouter()
    const language = useLanguage()

    const push = <T extends Paths>({ path, params }: RouterProps<T>) => router.push(href({ language, path, params } as HrefProps<T>))

    const replace = <T extends Paths>({ path, params }: RouterProps<T>) => router.replace(href({ language, path, params } as HrefProps<T>))

    return { push, replace }
}
