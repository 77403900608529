'use client'

import { Breakpoints } from '@mui/material'
import { createTheme } from '@mui/material/styles'

import { breakpoints } from './breakpoints'
import { components } from './components'
import { EVPalette, palette } from './palette'
import { typography } from './typography'

export type ThemeBreakpoints = Breakpoints & typeof breakpoints

declare module '@mui/material/styles' {
    interface Palette extends EVPalette {}

    interface PaletteOptions extends EVPalette {}
}

const theme = createTheme({
    breakpoints,
    typography,
    palette,
    components,
})

export const HEADER_HEIGHT = 64
export const DRAWER_WIDTH = 72

export const SEARCH_FIELD_WIDTH = 400
export const MAP_SEARCH_FIELD_WIDTH = 348

export { theme }
