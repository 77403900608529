import { TFunction } from '../../lib/i18n/use-translation'

import { BusinessUnit } from '../../../lib/generated/graphql'

export enum CountryAccessGroup {
    AMERICAS = 'AMERICAS',
    BENELUX = 'BENELUX',
    DACH = 'DACH',
    IBERIA = 'IBERIA',
    ROW = 'ROW',
}

export const AllCountriesPostfix = 'all_countries'

export const CountryAccessGroupClaimPrefix = 'sg_lmt_view'

export type CountryAccessGroupClaim =
    | `${typeof CountryAccessGroupClaimPrefix}_${CountryAccessGroup}_${BusinessUnit}`
    | `${typeof CountryAccessGroupClaimPrefix}_${typeof AllCountriesPostfix}`

export type UsersCountryAccessInfo = {
    countryAccessGroup: CountryAccessGroup | typeof AllCountriesPostfix | string
    businessUnit?: BusinessUnit
}

export const translateCountryAccessGroup = (
    t: TFunction,
    countryAccessGroup: CountryAccessGroup | typeof AllCountriesPostfix | string,
): string => {
    // NOTE: We're currently not maintaining translations for all countries in A3, so we're just using the enum value or the Country Code A3 as the translation
    if (CountryAccessGroup[countryAccessGroup as CountryAccessGroup]) {
        return t(`enum.CountryAccessGroup.${countryAccessGroup as CountryAccessGroup}`)
    }

    if (countryAccessGroup.toLowerCase() === AllCountriesPostfix) {
        return t(`enum.CountryAccessGroup.${countryAccessGroup as CountryAccessGroup}`)
    }

    return countryAccessGroup
}

// Note: This is a list of all possible CountryAccessGroupClaims permutations, just
//       kept this for reference, we might want to build a map out of it later on.
// export const CountryAccessGroups: CountryAccessGroupClaim[] = [
//     ...Object.values(BusinessUnit)
//         .map((businessUnit) =>
//             Object.values(CountryAccessGroup).map(
//                 (countryAccessGroup) => `${CountryAccessGroupClaimPrefix}${countryAccessGroup}_${businessUnit}`,
//             ),
//         )
//         .flat(),
//     `${CountryAccessGroupClaimPrefix}_${AllCountriesPostfix}`,
// ] as CountryAccessGroupClaim[]
