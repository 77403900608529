import { HrefProps } from '../hooks/use-localized-router'
import { Paths, paths } from '../types/routes'

export const href = <T extends Paths>({ path, params, language }: HrefProps<T>): string => {
    let href = `/${language}${paths[path]}`

    if (params) {
        for (const [key, value] of Object.entries(params)) {
            href = href.replace(`:${key}`, value)
        }
    }

    return href
}
