import { useCallback, useContext } from 'react'

import { TranslationValues } from './i18n'
import { Namespace, TranslationKey } from './i18n-server'
import { tReplace } from './t-replace'
import { TranslationContext } from './translation-context'

export type TFunction = (key: TranslationKey, values?: TranslationValues) => string

export const useTranslation = <N extends Namespace>(namespace: N) => {
    const dict = useContext(TranslationContext)

    const t = useCallback(
        (key: TranslationKey<N>, values?: TranslationValues): string => {
            const translated = dict?.[namespace][key] as string | undefined

            if (!translated) {
                console.warn(`Translation missing for key: ${String(key)}`)
                return ''
            }
            if (!values) {
                return translated
            }

            return tReplace(translated, values)
        },
        [dict, namespace],
    )

    return { t }
}
