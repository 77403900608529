'use client'

import { useCallback } from 'react'

import { usePathname } from 'next/navigation'

import { defaultLanguage, languages } from '../lib/i18n/i18n'

export const useLanguage = () => {
    const pathname = usePathname()

    const getCurrentLanguage = useCallback(() => {
        const locale = pathname?.split('/')[1]
        for (const language of languages) {
            if (locale === language) {
                return language
            }
        }

        return defaultLanguage
    }, [pathname])

    return getCurrentLanguage()
}
